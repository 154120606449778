import _objectSpread from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { defineComponent, inject, reactive, ref, toRefs } from 'vue';
import Header from '@/components/Header.vue';
import LeftFrame from '@/components/personal/LeftFrame.vue';
import DialogComponent from '@/components/share/Dialog.vue';
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { ERROR_MESSAGE } from '@/constants';
import EmailBindingDialog from '@/components/personal/EmailBindingDialog.vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'LoginMethod',
  components: {
    HeaderComponent: Header,
    LeftFrame: LeftFrame,
    DialogComponent: DialogComponent,
    EmailBindingDialog: EmailBindingDialog,
    SvgIcon: SvgIcon
  },
  setup: function setup() {
    var t = inject('t');
    var isMobile = inject('isMobile');
    var isEmailConnect = ref(false);
    var isConnectFailed = ref(false);

    var _useForm = useForm({
      initialValues: {
        email: '',
        booking_number: ''
      },
      validationSchema: yup.object({
        email: yup.string().required(t(ERROR_MESSAGE.REQUIRED)).email(t(ERROR_MESSAGE.EMAIL)),
        booking_number: yup.string().required(t(ERROR_MESSAGE.REQUIRED))
      })
    }),
        handleSubmit = _useForm.handleSubmit;

    var _useField = useField('email'),
        email = _useField.value,
        emailErrorMsg = _useField.errorMessage;

    var _useField2 = useField('booking_number'),
        bookingNumber = _useField2.value,
        bookingNumberErrorMsg = _useField2.errorMessage;

    var form = reactive({
      email: email,
      bookingNumber: bookingNumber
    });
    var errorMsg = reactive({
      email: emailErrorMsg,
      bookingNumber: bookingNumberErrorMsg
    });

    var setEmailConnectDialogOpen = function setEmailConnectDialogOpen(val) {
      isEmailConnect.value = val;
    };

    var setConnectDialogOpen = function setConnectDialogOpen(val) {
      isConnectFailed.value = val;
    };

    return _objectSpread(_objectSpread({
      t: t,
      isMobile: isMobile
    }, toRefs(form)), {}, {
      errorMsg: errorMsg,
      isEmailConnect: isEmailConnect,
      setEmailConnectDialogOpen: setEmailConnectDialogOpen,
      isConnectFailed: isConnectFailed,
      setConnectDialogOpen: setConnectDialogOpen
    });
  }
});