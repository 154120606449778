import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-fa8e1a68"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "dialog-content"
};
var _hoisted_2 = {
  class: "description"
};
var _hoisted_3 = {
  class: "btn-groups"
};
var _hoisted_4 = {
  class: "dialog-content"
};
var _hoisted_5 = {
  class: "description"
};
var _hoisted_6 = {
  class: "img-div"
};
var _hoisted_7 = {
  class: "hint"
};
var _hoisted_8 = {
  class: "btn-groups"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_field = _resolveComponent("field");

  var _component_dialog_component = _resolveComponent("dialog-component");

  var _component_svg_icon = _resolveComponent("svg-icon");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_dialog_component, {
    open: _ctx.isEmailConnect,
    title: _ctx.t('homepage.email_address_linking'),
    width: _ctx.isMobile ? 'calc(100% - 32px)' : 600,
    onClose: function onClose() {
      return _ctx.setEmailConnectDialogOpen(false);
    }
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('homepage.set_the_email_address_and_password_for_you_to_login')), 1), _createVNode(_component_field, {
        type: "email",
        modelValue: _ctx.email,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.email = $event;
        }),
        label: _ctx.t('homepage.e_mail'),
        size: "large",
        placeholder: _ctx.t('homepage.please_enter_your_e_mail'),
        errorMessage: _ctx.errorMsg.email
      }, null, 8, ["modelValue", "label", "placeholder", "errorMessage"]), _createVNode(_component_field, {
        type: "text",
        modelValue: _ctx.bookingNumber,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return _ctx.bookingNumber = $event;
        }),
        label: _ctx.t('homepage.order_number'),
        size: "large",
        placeholder: _ctx.t('homepage.please_enter_your_order_number'),
        errorMessage: _ctx.errorMsg.bookingNumber
      }, null, 8, ["modelValue", "label", "placeholder", "errorMessage"]), _createVNode(_component_field, {
        type: "text",
        modelValue: _ctx.bookingNumber,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
          return _ctx.bookingNumber = $event;
        }),
        label: _ctx.t('homepage.order_number'),
        size: "large",
        placeholder: _ctx.t('homepage.please_enter_your_order_number'),
        errorMessage: _ctx.errorMsg.bookingNumber
      }, null, 8, ["modelValue", "label", "placeholder", "errorMessage"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("button", {
        class: "btn",
        onClick: _cache[3] || (_cache[3] = function () {
          return _ctx.setEmailVerifiedDialogOpen(true);
        })
      }, _toDisplayString(_ctx.t('homepage.done')), 1)])])];
    }),
    _: 1
  }, 8, ["open", "title", "width", "onClose"]), _createVNode(_component_dialog_component, {
    open: _ctx.isEmailVerified,
    title: _ctx.t('homepage.verify_email_address'),
    width: _ctx.isMobile ? 'calc(100% - 32px)' : 600,
    onClose: function onClose() {
      return _ctx.setEmailVerifiedDialogOpen(false);
    }
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('homepage.to_ensure_account_security_we_have_sent_a_verification_letter_to_your_email_address_please_follow_the_instructions_within_to_verify_your_email_address')), 1), _createElementVNode("div", _hoisted_6, [_createVNode(_component_svg_icon, {
        iconName: "sendmail_animation_200x200",
        class: "email-img"
      })]), _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('homepage.the_verification_letter_has_been_sent_to_n', {
        n: 'tttfrfdiogjo@gmail.com'
      })), 1), _createElementVNode("div", _hoisted_8, [_createElementVNode("button", {
        class: "btn text-btn",
        onClick: _cache[4] || (_cache[4] = //@ts-ignore
        function () {
          return _ctx.handleSearch && _ctx.handleSearch.apply(_ctx, arguments);
        })
      }, _toDisplayString(_ctx.isMailSent ? _ctx.t('homepage.resending_available_after_{n}_seconds', {
        n: 60
      }) : _ctx.t('homepage.resend')), 1)])])];
    }),
    _: 1
  }, 8, ["open", "title", "width", "onClose"])], 64);
}